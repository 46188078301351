import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { FormProvider, useForm, useFormContext, validationError } from '@rvf/react-router'
import { withZod } from '@rvf/zod'
import { isPossiblePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js'
import { ActionFunction, Link, MetaFunction, data as json, redirect, useActionData, useNavigation } from 'react-router'
import z from 'zod'
import { zfd } from 'zod-form-data'
import { InputOTP, InputOTPGroup, InputOTPSlot } from '~/components/ui/input-otp'
import { cn } from '~/lib/utils'
import { findUser, login, withAuth } from '~/services/auth.server'
import { sendSMS } from '~/services/sms.server'

const validator = withZod(
  z.discriminatedUnion('step', [
    z.object({
      step: z.literal('login'),
      phone: zfd
        .numeric()
        .refine(value => isPossiblePhoneNumber(String(value), 'IR'), { message: 'شماره موبایل وارد شده معتبر نیست' })
        .transform(value => {
          return parsePhoneNumberFromString(String(value), {
            defaultCountry: 'IR',
          })
            ?.formatInternational()
            .replaceAll(' ', '')
        }),
    }),
    z.object({
      step: z.literal('verify'),
      code: z.string().length(6),
      phone: zfd
        .numeric()
        .refine(value => isPossiblePhoneNumber(String(value), 'IR'), { message: 'شماره موبایل وارد شده معتبر نیست' })
        .transform(value => {
          return parsePhoneNumberFromString(String(value), {
            defaultCountry: 'IR',
          })
            ?.formatInternational()
            .replaceAll(' ', '')
        }),
    }),
  ]),
)

const clientValidator = withZod(
  z.discriminatedUnion('step', [
    z.object({
      step: z.literal('login'),
      phone: z.string().min(11, 'لطفا شماره موبایل معتبر وارد کنید.'),
    }),
    z.object({
      step: z.literal('verify'),
      code: z.string().min(6).max(6),
      phone: z.string().min(11, 'لطفا شماره موبایل معتبر وارد کنید.'),
    }),
  ]),
)
export const meta: MetaFunction = () => {
  return [
    {
      title: 'ورود | مآریان اسپورت',
    },
  ]
}
export const action: ActionFunction = async ({ request, context }) => {
  const DB = (context.cloudflare.env as Env).DB
  const KV = (context.cloudflare.env as Env).KV
  const data = await validator.validate(await request.formData())
  if (data.error) return validationError(data.error)
  const { step, phone } = data.data

  if (step === 'login') {
    try {
      await findUser(DB, phone!)
    } catch (e) {
      return {
        formId: 'login',
        fieldErrors: {
          phone: 'شماره تلفن وارد شده معتبر نیست.',
        },
      }
    }
    const code = Math.floor(Math.random() * 900000) + 100000
    if ((await KV.get(`login-${phone}`)) === null) {
      await sendSMS(context.cloudflare.env.SMS_IR_TOKEN, context.cloudflare.env.SMS_IR_LINE_NUMBER, phone, context.cloudflare.env.SMS_OTP_PATTERN_ID, [{ name: 'code', value: code.toString() }])
      await KV.put(`login-${phone}`, code.toString(), {
        expirationTtl: 60 * 2,
      })
    } else {
      console.log('exists', phone, await KV.get(`login-${phone}`))
    }
    return json({
      step: 'verify',
      phone,
    })
  } else {
    const code = await KV.get(`login-${phone}`)
    if (code !== data.data.code)
      return json({
        step: 'verify',
        phone,
        ...{
          formId: 'login',
          fieldErrors: {
            code: 'کد وارد شده صحیح نیست.',
          },
        },
      })
    else {
      return redirect('/dashboard', {
        headers: {
          'Set-Cookie': await login(request, context, phone!),
        },
      })
    }
  }
}

export const loader = withAuth({ roles: [], redirectOnFail: '/dashboard' })

export default function AdminLogin() {
  const actionData = useActionData<{ step: 'verify' | 'login'; phone?: string }>()
  const step = actionData?.step || 'login'
  const form = useForm({
    validator: clientValidator,
    id: 'login',
    method: 'post',
    validationBehaviorConfig: {
      initial: 'onSubmit',
      whenSubmitted: 'onSubmit',
      whenTouched: 'onSubmit',
    },
  })

  return (
    <div className="flex flex-col min-h-[100dvh] items-center justify-center bg-gray-100 px-4 dark:bg-gray-950 direction-rtl">
      <div className="mx-auto w-full max-w-md space-y-4 rounded-lg bg-white p-6 shadow-lg dark:bg-gray-900">
        <div className="flex justify-center mb-4 py-4">
          <img src="/images/logo.png" alt="Logo" className="max-w-52" />
        </div>
        {step === 'login' && (
          <div className="space-y-2 text-center">
            <h1 className="text-xl font-bold">خوش آمدید</h1>
            <p className="text-gray-500 dark:text-gray-400">شماره تلفن خود را وارد کنید تا وارد شوید.</p>
          </div>
        )}
        {step === 'verify' && (
          <div className="space-y-2 text-center">
            <h1 className="text-xl font-bold">کد تایید را وارد کنید</h1>
            <p className="text-gray-500 dark:text-gray-400">کد تایید ارسال شده به شماره تلفن شما را وارد کنید.</p>
          </div>
        )}
        <FormProvider scope={form.scope()}>
          <form className="space-y-4" {...form.getFormProps()}>
            <LoginForm />
          </form>
        </FormProvider>
      </div>
      <span className="mt-6">
        ثبت نام نکرده اید؟{' '}
        <Link to="/register" className="text-blue-600">
          ثبت نام کنید
        </Link>
      </span>
    </div>
  )
}

const LoginForm = () => {
  const actionData = useActionData<{ step: 'verify' | 'login'; phone?: string }>()
  const step = actionData?.step || 'login'
  const isSubmitting = useNavigation().state === 'submitting'
  const form = useFormContext<{ code: string; phone: string; step: string }>()

  return (
    <>
      {step === 'login' && (
        <>
          <input {...form.getInputProps('step')} hidden value="login" readOnly />
          <div className="space-y-2">
            <Label htmlFor="phone">شماره تلفن</Label>
            {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
            <Input error={form.error('phone')} autoFocus placeholder="09123456789" type="tel" className="text-center" {...form.getInputProps('phone')} />
          </div>
          <Button className="w-full" type="submit" isLoading={isSubmitting}>
            ادامه
          </Button>
        </>
      )}
      {step === 'verify' && (
        <>
          <input {...form.getHiddenInputProps('step')} value="verify" readOnly />
          <input {...form.getHiddenInputProps('phone')} readOnly />
          <div className="space-y-2">
            <Label htmlFor="code">کد تایید</Label>
            <InputOTP {...form.getInputProps('code')} data-invalid={!!form.error('code')} maxLength={6} onComplete={form.submit} render={undefined}>
              <InputOTPGroup className={cn('flex justify-center mx-auto group w-full')} data-invalid={!!form.error('code')} dir="ltr">
                {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
                <InputOTPSlot autoFocus index={0} />
                <InputOTPSlot index={1} />
                <InputOTPSlot index={2} />
                <InputOTPSlot index={3} />
                <InputOTPSlot index={4} />
                <InputOTPSlot index={5} />
              </InputOTPGroup>
            </InputOTP>
            {<span className="text-red-400 text-sm">{form.error('code')}</span>}
          </div>
          <Button className="w-full" type="submit" isLoading={isSubmitting}>
            تایید
          </Button>
        </>
      )}
    </>
  )
}
